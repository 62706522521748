import Head from "next/head";
import Image from "next/image";
import {useState} from "react";

import styles from "../styles/Home.module.scss";

export default function Home() {
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [signedUp, setSignedUp] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const subscribe = async () => {
    setSubmitting(true);

    try {
      const response = await fetch("/api/subscribe", {
        method: "POST",
        body: JSON.stringify({email}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.error) {
        setError(data.error);
        return;
      }
    } catch (error: any) {
      setError(error.message || error.toString());
      return;
    } finally {
      setSubmitting(false);
    }

    // Signup complete
    setEmail("");
    setSignedUp(true);
  };

  return (
    <>
      <div className={styles.container}>
        <Head>
          <title>Camp Observer</title>
          <meta name="description" content="Camp Observer" />
        </Head>

        <main className={styles.main}>
          <Image
            className="logo"
            src="/images/logo.png"
            alt="Camp Observer"
            width={833}
            height={259}
          />

          <div className={styles.overview}>
            <p className={styles.description}>
              <strong>Looking for that hard-to-book camp site?</strong>
              <br />
              We&apos;ve got you covered!
            </p>

            {signedUp ? (
              <div className={styles.signupComplete}>
                You&apos;ve been added to the waitlist!
              </div>
            ) : (
              <div className={styles.signupStart}>
                <p className={styles.subtext}>
                  We&apos;re in a private beta test. Want to join or know when
                  we launch?
                </p>

                <form onSubmit={async (event) => {
                    event.preventDefault();
                    await subscribe();
                  }}>
                  <input
                    type="text"
                    className={styles.emailInput}
                    placeholder="Enter your email address..."
                    disabled={submitting}
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                  />

                  <button
                    type="submit"
                    className={styles.cta}
                    disabled={submitting}
                    onClick={async event => {
                      event.preventDefault();
                      await subscribe();
                    }}>
                    Join Waitlist
                  </button>

                  {error && <div className={styles.error}>Error: {error}</div>}
                </form>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
